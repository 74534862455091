import React from 'react';

import { h3Style, pNormal } from '@/components/hallucination-index-2024/common';
import {
  IMcrLcrModel,
  lcrHeatmapModels,
} from '@/components/hallucination-index-2024/model-data-helpers';
import Chart from '../common/chart';
import Heatmap from '../common/heatmap';
import ResultSnapshot from '../common/result-snapshot';
import RagTaskExamples from '../common/rag-task-examples';
import AnthropicLogo from '@/assets/anthropic.svg';
import GoogleLogo from '@/assets/google.svg';
import { RagTaskExampleData } from '../rag-task-examples-data';

const defaultSelectedChartFilters = [
  {
    name: 'promptType',
    values: ['Simple'],
  },
];

const resultSnapshotItems = [
  {
    category: 'Best closed-source model',
    icon: <AnthropicLogo />,
    modelName: `Anthropic’s Claude-3-5-Sonnet`,
    text: `During long context testing, many models scored a perfect 1.0 score, highlighting the power and quality of models from top-tier providers. Among those scoring a perfect 1.0 were:\n
    • Anthropic: claude-3-5-sonnet, claude-3-opus\n• Google: gemini-1.5-pro-001\n• Open AI: gpt-4o

    Between these models, it came down to Claude-3-5-Sonnet and Gemini-1.5-pro-001, which were both comparable on performance and price. Ultimately we chose Claude-3-5-Sonnet due to its better performance across short and medium-context testing.
 `,
    score: 1.0,
  },
  {
    category: 'Worst closed-source model',
    icon: <AnthropicLogo />,
    modelName: `Anthropic’s Claude-3-Haiku`,
    text: `Claude-3-haiku performed the worst with a score of 0.7. During testing, the model struggled to maintain accuracy with context lengths greater than 60k tokens.`,
    score: 0.7,
  },
  {
    category: 'Best performance for the cost',
    icon: <GoogleLogo />,
    modelName: `Google’s Gemini-1.5-flash-001`,
    text: `Google's gemini-1.5-flash-001 scored an impressive 0.92 and only faced issues when provided with 80000 token context length.`,
    score: 0.92,
  },
];

const heatmapData = {
  type: 'lcr' as const,
  models: lcrHeatmapModels,
  title: 'Recall heatmap',
  description: `As the name suggests, this heatmap indicates the model's success in recalling information at different locations in the context. Green signifies success, while red indicates failure.`,
};

type Props = {
  lcrModels: IMcrLcrModel[];
  ragTaskExamples: RagTaskExampleData[];
};

const LCR = ({ lcrModels, ragTaskExamples }: Props) => {
  return (
    <section className="mt-12">
      {/* Head */}
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="md:w-1/2">
          <h3 className={h3Style}>Long Context RAG (LCR)</h3>
          <p className="my-3 text-lg leading-8 !text-black lg:text-[22px]">
            40k to 100k tokens
          </p>
        </div>

        <p className={`${pNormal} !text-[#454547] md:w-1/2`}>
          This heatmap shows the model's ability to recall information in
          different parts of the context. The x-axis represents the length of
          the context during the experiment, and the y-axis represents the
          location of the information. Green indicates successful recall, while
          red indicates failure.
        </p>
      </div>

      {/* Chart */}
      <div className="my-16">
        <Chart
          maxYTickOffset={0.05}
          minYTickOffset={-0.1}
          models={lcrModels}
          enabledFilters={[
            'type',
            'contextLength',
            'promptType',
            'pricePerToken',
            'responseCost',
          ]}
          defaultSelectedChartFilters={defaultSelectedChartFilters}
        />
      </div>
      {/* Result Snapshot */}
      <ResultSnapshot items={resultSnapshotItems} />
      {/* Heatmap */}
      <Heatmap {...heatmapData} />
      {/* RAG Task Examples */}
      <RagTaskExamples items={ragTaskExamples} />
    </section>
  );
};

export default LCR;
