import React from 'react';

import { h3Style, pNormal } from '@/components/hallucination-index-2024/common';
import Chart from '../common/chart';
import ResultSnapshot from '../common/result-snapshot';
import RagTaskExamples from '../common/rag-task-examples';

import AnthropicLogo from '@/assets/anthropic.svg';
import GoogleLogo from '@/assets/google.svg';

import { IScrModel } from '@/components/hallucination-index-2024/model-data-helpers';
import CohereLogo from '@/assets/cohere-logo.svg';
import PricingInfo from '../common/pricing-info';
import { RagTaskExampleData } from '../rag-task-examples-data';

const MetaLogo = () => <img width={39} height={39} src="/creators/meta.png" />;

const resultSnapshotItems = [
  {
    category: 'Best closed source model',
    icon: <AnthropicLogo />,
    modelName: `Anthropic’s Claude-3-5-Sonnet`,
    text: `Claude-3-5-Sonnet and Claude-3-opus tied with scores of 0.97 followed closely by Open AI’s GPT-4o with a score of 0.96. Sonnet was our choice as it achieved this performance at a lower cost than its close competitors. `,
    score: 0.97,
  },
  {
    category: 'Worst closed-source model',
    icon: <CohereLogo />,
    modelName: `Cohere's Command-r-plus`,
    text: `Cohere's Command-r-plus earned a 0.86 during short context testing. While this was better than Open AI’s GPT-3.5-Turbo and Google’s Gemini which both scored a 0.84, Cohere’s price was on par with Anthropic’s Claude 3.5 Sonnet at $3/M prompt tokens and $15/M response tokens, making it our choice for the worst closed-source model.`,
    score: 0.86,
  },
  {
    category: 'Best open-source model',
    icon: <MetaLogo />,
    modelName: `Meta's llama-3-70b-instruct`,
    text: `Alibaba's qwen2-72b-instruct and Meta's llama-3-70b-instruct tied during testing with scores of 0.95. We chose llama-3-70b-instruct, as qwen2-72b-instruct has 60% longer responses, which could increase the cost.`,
    score: 0.95,
  },
  {
    category: 'Worst open-source model',
    icon: <GoogleLogo />,
    modelName: `Google’s Gemma-7b-it`,
    text: `Google's Gemma-7b-it demonstrated the poorest performance within the 7-billion-parameter category.`,
    score: 0.65,
  },
  {
    category: 'Best performance for the cost',
    icon: <GoogleLogo />,
    modelName: `Google’s Gemini-1.5-flash-001`,
    text: `As mentioned in our overall results, Gemini-1.5-flash-001 performed the best at a fraction of the cost of models like Claude-3.5-sonnet, making it our choice for this category.`,
    score: 0.94,
  },
  {
    category: 'Best small open model',
    icon: <MetaLogo />,
    modelName: `Meta's llama-3-8b-instruct`,
    text: `Meta's llama-3-8b-instruct surpassed several recent large models like Snowflake's Arctic.`,
    score: 0.89,
  },
];

type Props = {
  scrModels: IScrModel[];
  ragTaskExamples: RagTaskExampleData[];
};

const SCR = ({ scrModels, ragTaskExamples }: Props) => {
  return (
    <section className="my-12 lg:mb-24 lg:mt-12">
      {/* Head */}
      <div className="flex flex-col gap-6 md:flex-row">
        <div className="md:w-1/2">
          <h3 className={h3Style}>Short Context RAG (SCR)</h3>
          <p className="my-3 text-lg leading-8 !text-black lg:text-[22px]">
            Less than 5k tokens
          </p>
        </div>

        <p className={`${pNormal} !text-[#454547] md:w-1/2`}>
          The Short Context RAG seeks to identify the most efficient model for
          understanding contexts up to 5k tokens. Its primary goal is to detect
          any loss of information or reasoning capability within these contexts.
          Similar to referencing select pages in a book, this method is
          especially suitable for tasks that demand domain-specific knowledge.
        </p>
      </div>

      {/* Chart */}
      <div className="my-16">
        <Chart
          maxYTickOffset={0.08}
          models={scrModels}
          enabledFilters={[
            'type',
            'contextLength',
            'pricePerToken',
            'responseCost',
          ]}
        />
        <PricingInfo />
      </div>
      {/* Result Snapshot */}
      <ResultSnapshot items={resultSnapshotItems} />
      {/* RAG Task Examples */}
      <RagTaskExamples items={ragTaskExamples} />
    </section>
  );
};

export default SCR;
