import React from 'react';

import { h2Style, h5Style, h6Style, pNormal } from '../common';

const trendList = [
  {
    label: '01',
    title: 'Open source is closing the gap',
    text: 'While closed-source models still offer the best performance thanks to proprietary training data, open-source models like Gemini, Llama, and Qwen continue to improve in hallucination performance without the cost barriers of their close-sourced counterparts.',
  },
  {
    label: '02',
    title: `What context length?`,
    text: 'We were surprised to find models perform particularly well with extended context lengths without losing quality or accuracy, reflecting how far model training and architecture has come.',
  },
  {
    label: '03',
    title: 'Larger is not always better',
    text: `In certain cases, smaller models outperformed larger models. For example Gemini-1.5-flash-001 outperformed larger models, which suggests that efficiency in model design can sometimes outweigh scale.`,
  },
  {
    label: '04',
    title: `Anthropic outperforms OpenAl`,
    text: `During testing, Anthropic's latest Claude 3.5 Sonnet and Claude 3 Opus consistently scored close to perfect scores, beating out GPT-4o and GPT-3.5, especially in shorter context scenarios.`,
  },
];

const Trends = () => {
  return (
    <div className="bg-[#070707]">
      <section className="mx-auto flex max-w-[90rem] flex-col px-5 lg:px-[85px]">
        <div className="flex items-center gap-2 border border-t-0 border-gray-400/40 p-4 lg:px-4 lg:py-9">
          <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
            Trends
          </h2>
        </div>
        <div className="flex flex-col justify-between border border-t-0 border-gray-400/40 md:flex-row">
          {trendList.map((trend) => (
            <div
              key={trend.label}
              className="flex flex-col border border-gray-400/40 px-4 py-10 md:w-3/12"
            >
              <h6 className="text-5xl font-bold leading-[normal] text-[#5C5CFF]">
                {trend.label}
              </h6>
              <h4 className="mb-6 text-[28px] font-medium leading-[normal] text-white md:min-h-[200px]">
                {trend.title.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </h4>
              <p className="text-base font-normal leading-[175%] text-white">
                {trend.text}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Trends;
