import React from 'react';

import { h4Style } from '@/components/hallucination-index-2024/common';
import AppTooltip from '@/components/common/app-tooltip';

interface IResult {
  category: string;
  icon: JSX.Element;
  modelName: string;
  text: string;
  score: number;
}

type Props = {
  items: IResult[];
};

const ResultSnapshot = ({ items }: Props) => {
  return (
    <section className="my-10 w-full lg:my-20">
      {/* Title */}
      <h4 className={`${h4Style}`}>Result Snapshot</h4>

      {/* List */}
      <div className="my-4 grid w-full grid-cols-6">
        <div className="col-span-2 p-3 text-black/80">Category</div>
        <div className="col-span-1 p-3 text-black/80">Provider</div>
        <div className="col-span-2 p-3 text-black/80">Description</div>
        <div className="col-span-1 flex gap-1 p-3 text-black/80">
          Score
          <AppTooltip
            id="score-tooltip"
            content="Context Adherence is a measurement of closed-domain hallucinations: cases where your model said things that were not provided in the context.If a response is adherent to the context (i.e. it has a value of 1 or close to 1), it only contains information given in the context. If a response is not adherent (i.e. it has a value of 0 or close to 0), it's likely to contain facts not included in the context provided to the model."
          >
            <img
              className="cursor-pointer"
              src="/info-icon.svg"
              alt="Info icon"
            />
          </AppTooltip>
        </div>

        {items.map((item) => (
          <>
            <div className="col-span-2 border-t border-t-black/20 px-3 py-5">
              <p className="text-base font-medium">{item.category}</p>
            </div>
            <div className="col-span-1 border-t border-t-black/20 px-3 py-5 text-left">
              {item.icon}
            </div>
            <div className="col-span-2 border-t border-t-black/20 px-3 py-5">
              <p className="whitespace-pre-line text-base leading-6">
                <strong>{item.modelName}</strong>
                <br />
                {item.text}
              </p>
            </div>
            <div className="col-span-1 border-t border-t-black/20 px-3 py-5">
              <div className="flex flex-row items-center gap-2">
                <span className="text-xl font-bold lg:text-2xl">
                  {item.score.toFixed(2)}
                </span>
                <div className="h-3 w-20 bg-hi-3">
                  <div
                    style={{ width: `${item.score * 100}%` }}
                    className={`h-full bg-[#47C278]`}
                  />
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </section>
  );
};

export default ResultSnapshot;
