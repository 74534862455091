import React from 'react';

const ModelSection: React.FC<{
  description: string;
  modelName: string;
  title: string;
  Logo: any;
}> = ({ title, modelName, description, Logo }) => {
  return (
    <section className="mx-auto max-w-[90rem] px-5 lg:px-[85px]">
      <div className="border border-gray-400/40 p-4 lg:px-4 lg:py-12">
        <div className="flex flex-col items-start gap-4 md:flex-row md:gap-6 lg:gap-20">
          <div className="flex flex-1 flex-col items-baseline gap-4 md:flex-row md:items-center md:gap-6">
            <h5 className="flex-1 text-[22px] font-medium leading-[normal] text-white">
              {title}
            </h5>
            <div className="flex items-center gap-2">
              <Logo className="w-16" />
              <span className="text-base font-medium leading-[175%] !text-[#fffc]">
                {modelName}
              </span>
            </div>
          </div>
          <p className="text-base font-normal leading-[175%] text-white md:max-w-[400px]">
            {description}
          </p>
        </div>
      </div>
    </section>
  );
};

export default ModelSection;
