import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { h4Style } from '@/components/hallucination-index-2024/common';
import Dropdown from '../../dropdown';
import { LinkWrapper } from '@/components/hallucination-index-2024/common';

type Props = {
  models: { name: string }[];
  title: string;
  description: string;
  type: 'mcr' | 'lcr';
};

const Heatmap = ({ models, title, description, type }: Props) => {
  const [currentModel, setCurrentModel] = React.useState(models[0].name);
  const [filter, setFilter] = React.useState('simple');

  const dropdownItems = models.map((model) => ({
    label: model.name,
    value: model.name,
  }));

  const filterDropdownItems = [
    {
      label: 'Simple',
      value: 'simple',
    },
    {
      label: 'Chain-of-Note',
      value: 'with-con',
    },
  ];

  return (
    <section className="my-6 w-full lg:my-10">
      {/* Heading */}
      <div className="mb-6 lg:mb-10">
        <h4 className={`${h4Style} mb-3`}>{title}</h4>
        <p className="max-w-[775px] text-base font-normal leading-7 text-black">
          {description}
        </p>
      </div>

      <div className="mt-4 flex flex-col items-center gap-4 sm:flex-row">
        <Dropdown
          items={dropdownItems}
          onChange={(v) => {
            setCurrentModel(v);
            setFilter('simple');
          }}
          defaultSelected={0}
          value={dropdownItems.find((item) => item.value === currentModel)}
        />

        <Dropdown
          items={filterDropdownItems}
          onChange={(v) => setFilter(v)}
          defaultSelected={0}
          value={filterDropdownItems.find((item) => item.value === filter)}
        />
      </div>

      <p className="mt-4 text-xl leading-7 text-black lg:text-[22px] lg:leading-9">
        Heatmap for{' '}
        <LinkWrapper
          className="capitalize text-hi-100 hover:opacity-80"
          label={currentModel}
          type="internalLink"
          url={`${currentModel}`}
        />
      </p>

      <div className="w-full">
        <img
          alt={currentModel}
          src={`/hi/${type}-${filter}/${currentModel}.png`}
          className="w-full"
        />
      </div>
    </section>
  );
};

export default Heatmap;
