import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@/components/seo';
import MarketingLayout from '@/components/common/hl-marketing-layout';

import Hero from '@/components/hallucination-index-2024/index-page/hero';
import IntroSection from '@/components/hallucination-index-2024/index-page/intro-section';
import Trends from '@/components/hallucination-index-2024/index-page/trends';
import TopModels from '@/components/hallucination-index-2024/index-page/top-models';
import Tabs from '@/components/hallucination-index-2024/index-page/tabs';
import ModelComparison from '@/components/hallucination-index-2024/index-page/model-comparison';
import PerformanceDataset from '@/components/hallucination-index-2024/index-page/performance-dataset';
import PreFooter from '@/components/hallucination-index-2024/pre-footer';

import {
  formatScrData,
  formatMcrLcrData,
  formHiModelsData,
  formatRadarChartData,
  shortenModalName,
} from '@/components/hallucination-index-2024/model-data-helpers';

const HallucinationIndexPage = ({ data }) => {
  const { allScrCsv, allHiModelsCsv, allMcrCsv, allLcrCsv } = data;

  const scrModels = formatScrData(allScrCsv, allHiModelsCsv);
  const mcrModels = formatMcrLcrData(allMcrCsv, allHiModelsCsv);
  const lcrModels = formatMcrLcrData(allLcrCsv, allHiModelsCsv);

  const hiModels = formHiModelsData(allHiModelsCsv);

  const radarChartData = formatRadarChartData(
    allScrCsv,
    allMcrCsv,
    allLcrCsv,
    allHiModelsCsv,
  );

  return (
    <MarketingLayout>
      <Hero />
      <IntroSection />
      <Trends />
      <TopModels />
      <Tabs
        data={{
          scrModels: shortenModalName(scrModels),
          mcrModels: shortenModalName(mcrModels),
          lcrModels: shortenModalName(lcrModels),
        }}
      />
      {/* Model Comparision */}
      <ModelComparison
        hiModels={hiModels}
        scrModels={scrModels}
        mcrModels={mcrModels}
        lcrModels={lcrModels}
      />
      <PerformanceDataset models={radarChartData} />
      <PreFooter />
    </MarketingLayout>
  );
};

export const query = graphql`
  query csvData {
    allScrCsv {
      nodes {
        Model
        model_avg
        model_type
        _xMxpromptxtokens
        _xMxresponsextokens
        Task_insight

        ms_marco
        drop
        convfinqa
        hotpot_qa
      }
    }

    allMcrCsv {
      nodes {
        Model
        _xMxpromptxtokens
        _xMxresponsextokens
        model_avg
        model_type
        Task_insight
        prompt_type
      }
    }

    allLcrCsv {
      nodes {
        Model
        _xMxpromptxtokens
        _xMxresponsextokens
        model_avg
        model_type
        Task_insight
        prompt_type
      }
    }

    allHiModelsCsv {
      nodes {
        Model
        Developer
        License
        _xMxpromptxtokens
        _xMxresponsextokens
        Open_Closed
        Model_parameters
        Supported_context_length
      }
    }
  }
`;

export default HallucinationIndexPage;

export const Head = () => (
  <SEO
    title={`LLM Hallucination Index RAG Special - Galileo`}
    description={`LLM Hallucination Index RAG Special - Galileo. A Ranking & Evaluation Framework For LLM Hallucinations`}
    image="/hallucination-index-2024-og.png"
    imageWidth={1200}
    imageHeight={670}
  />
);
