import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

import { h2Style, pNormal, h5Style } from '../../common';

import AnthropicLogo from '@/assets/anthropic-w.svg';
import GoogleLogo from '@/assets/google.svg';
import QwenLogo from '@/assets/qwen.svg';
import MetaLogo from '@/assets/meta.svg';
import ModelSection from './model.section';
import TopModelSection from './top-model-section';

const overallWinners = [
  {
    label: 'Best performing model',
    icon: <AnthropicLogo className="w-16 min-w-16" />,
    text: 'Claude 3.5 Sonnet due to great performance on all tasks with context support up to 200k.',
    link: '/hallucinationindex/claude-3-5-sonnet-20240620',
  },
  {
    label: 'Best performance for the cost',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'Gemini 1.5 Flash due to great performance on all tasks with context support up to 1M.',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
  {
    label: 'Best performing open-source model',
    icon: <QwenLogo className="w-16 min-w-16" />,
    text: 'Qwen2-72B-Instruct due to great performance in SCR and MCR with context support up to 128k.',
    link: '/hallucinationindex/qwen2-72b-instruct',
  },
];

const shortContext = [
  {
    label: 'Best closed source model',
    icon: <AnthropicLogo className="w-16 min-w-16" />,
    text: 'Claude 3.5 Sonnet',
    value: '0.97',
    link: '/hallucinationindex/claude-3-5-sonnet-20240620',
  },
  {
    label: 'Best open source model',
    icon: <MetaLogo className="w-16 min-w-16" />,
    text: 'Llama-3-70b-chat',
    value: '0.95',
    link: '/hallucinationindex/meta-llama-3-70b-instruct',
  },
  {
    label: 'Best affordable model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'Gemini 1.5 Flash',
    value: '0.94',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
];

const mediumContext = [
  {
    label: 'Best closed source model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'Gemini 1.5 Flash',
    value: '1.00',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
  {
    label: 'Best open source model',
    icon: <QwenLogo className="w-16 min-w-16" />,
    text: 'Qwen1.5-32B-Chat',
    value: '0.99',
    link: '/hallucinationindex/qwen15-32b-chat',
  },
  {
    label: 'Best affordable model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'Gemini 1.5 Flash',
    value: '1.00',
    link: '/hallucinationindex/gemini-15-flash-001',
  },
];

const longContext = [
  {
    label: 'Best closed source model',
    icon: <AnthropicLogo className="w-16 min-w-16" />,
    text: 'Claude 3.5 Sonnet',
    value: '1.0',
    link: '/hallucinationindex/claude-3-5-sonnet-20240620',
  },
  {
    label: 'Best affordable model',
    icon: <GoogleLogo className="w-16 min-w-16" />,
    text: 'Gemini 1.5 Pro',
    value: '0.92',
    link: '/hallucinationindex/gemini-15-pro-001',
  },
];

const modelSections: {
  description: string;
  modelName: string;
  title: string;
  Logo: React.ElementType;
}[] = [
  {
    title: 'Best Model',
    description: `
      We were extremely impressed by Anthropic’s latest set of models.\n
      Not only was Sonnet able to perform excellently across short,\n
      medium, and long context windows, scoring an average of 0.97, 1.0,\n
      and 1.0 respectively across tasks, but the model's support of up\n
      to a 200k context window suggests it could support even larger\n
      datasets than we tested.
      `,
    modelName: 'Claude 3.5 Sonnet',
    Logo: AnthropicLogo,
  },
  {
    title: 'Best Performance for the Cost',
    modelName: 'Gemini 1.5 Flash',
    description: `
        Gemini 1.5 Flash offered a great balance of performance and cost.\n
        It earned a 0.94, 1.0, and 0.92 across short, medium, and long\n
        context task types. While not as robust as other models, Gemini\n
        did this at a fraction of the cost. The $ per Million prompt\n
        tokens cost was $0.35 for Flash vs. $3 for Sonnet. Even more\n
        starkly, the $ per Million response token cost was $1.05 for Flash\n
        vs. $15 for Sonnet. For high-volume applications or use cases\n
        where some margin of error is acceptable, Flash is a great choice.
      `,
    Logo: GoogleLogo,
  },
  {
    title: 'Best Open-Source Model',
    modelName: 'Qwen2-72b-instruct',
    description: `
        A newcomer to the Index, Alibaba launched its Qwen-2 model series\n
        in June 2024. The Qwen2-72b-instruct model performed on par with\n
        Meta’s Llama-3-70b-instruct model during short and medium context\n
        testing. What set Qwen2 apart from other open-source models was\n
        its supported context length of 128K tokens. For context, the next\n
        largest supported context length by an open source model was\n
        Mistral’s Mixtral-8x22b model, which supports a context length of\n
        64k tokens.
      `,
    Logo: QwenLogo,
  },
];

const TopModels = () => {
  const cellPadding = 'p-5';
  return (
    <div className="bg-[#070707]">
      <section className="mx-auto flex max-w-[90rem] flex-col px-5 lg:px-[85px]">
        <div className="flex items-center gap-2 border border-t-0 border-gray-400/40 p-4 lg:px-4 lg:py-9">
          <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
            Top Models for RAG Applications
          </h2>
        </div>

        <div className="flex flex-col">
          <div className="border-x border-gray-400/40 p-4 pb-8 pt-0 lg:px-4">
            <div className="flex flex-col gap-6 md:flex-row">
              <div
                className={`${h5Style} pt-10 !font-inter !text-white md:w-1/3`}
              >
                Overall Winners for RAG
              </div>

              <div className="flex w-full flex-col md:w-2/3">
                {overallWinners.map((item) => (
                  <a
                    href={item.link}
                    key={item.label}
                    className="gradient-to-b flex flex-wrap items-center justify-between gap-4 border-b border-gray-400/40 from-[#F5F5FA] to-[#DCDCE0] py-8 last:border-none md:flex-nowrap"
                  >
                    <div className="shrink-0 grow-0 sm:w-1/3">
                      <p
                        className={`${pNormal} w-full flex-grow-0 !font-medium !text-white opacity-80`}
                      >
                        {item.label}
                      </p>
                    </div>

                    <div className="flex w-[calc(100%_-_68px)] max-w-[400px] grow-0 flex-row items-center gap-4 md:ml-auto md:w-full">
                      {item.icon}
                      <p className={`${pNormal} font-medium !text-white`}>
                        {item.text}
                      </p>
                    </div>

                    <div className="w-12 sm:w-16">
                      <FiChevronRight className="h-8 w-8 !text-white" />
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <TopModelSection
        categoryTitle="Short Context RAG"
        categorySubtitle="(&lt;5k tokens)"
        contextList={shortContext}
        showHeader
      />
      <TopModelSection
        categoryTitle="Medium Context RAG"
        categorySubtitle="(5k to 25k tokens)"
        contextList={mediumContext}
      />
      <TopModelSection
        categoryTitle="Long Context RAG"
        categorySubtitle="(40k to 100k tokens)"
        contextList={longContext}
      />

      <section className="mx-auto max-w-[90rem] px-5 lg:px-[85px]">
        <div className="border border-gray-400/40 p-4 lg:px-4 lg:py-9">
          <h2 className="text-[34px] font-bold text-white md:text-7xl md:leading-[80px]">
            Overall Winners for RAG
          </h2>
          <p className="mb-6 mt-5 max-w-[810px] text-base font-normal leading-[175%] text-white">
            Our team conducted extensive testing over a two month period, with
            many iterations as newer models were released. We picked three
            overall winners across all tests and all context lengths.
          </p>
        </div>
      </section>

      {modelSections.map((model) => (
        <ModelSection
          key={model.title}
          title={model.title}
          modelName={model.modelName}
          description={model.description}
          Logo={model.Logo}
        />
      ))}
    </div>
  );
};

export default TopModels;
